import React, { useEffect, useContext } from "react"
import { Link, graphql } from "gatsby"
// import SEO from "../components/seo"
import styled from '@emotion/styled'
import { getImage } from "gatsby-plugin-image"
import formatDates, { formatAnchor } from "../../utils/formatDates"
import { useMerch } from "../../hooks/useMerch"
import Carousel from "../../components/Carousel"
import { MIN_DEFAULT_MQ } from "../../utils/presets"
import { CurrentContext } from "../../layouts"

const Article = styled(`article`)({
  margin: `0 0 2rem 0`,
  minHeight: `20rem`,
  [MIN_DEFAULT_MQ]: {
    maxWidth: `calc(100vw - 18rem)`
  }
})

const Header = styled(`header`)({
  marginBottom: `1rem`,
  [`&::before`]: {
    display: `block`,
    content: `" "`,
    visibility: `hidden`,
    pointerEvents: `none`,
    marginTop: `-76px`,
    height: `76px`,
    [MIN_DEFAULT_MQ]: {
      marginTop: `-64px`,
      height: `64px`,
    }
  }
})

const MerchImagesPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const merch = useMerch()
  const currentContext = useContext(CurrentContext)

  return (
    <React.Fragment>
      {/*<SEO title="All merch" />*/}
      <div>
        {merch.map(({ node }) => {
          const { displayDate } = formatDates(node.startDate, node.endDate, node.yearOnly)
          const { insta } = node || {}
          const { localFile = {}, carouselImages = [] } = insta || {}
          // if carouselImages exisits use it over localFile
          let images
          if (carouselImages.length > 0) {
            images = carouselImages.map(({ localFile }) => getImage(localFile))
          } else if (localFile) {
            images = getImage(localFile)
          }
          if (images && images.length > 1) {
            // move first item to the end of array
            images.push(images.shift())

            // add greyscale image to front of array
            images.unshift(getImage(localFile))

            // replace the first item in the array with the grayscale localFile
            // images.splice(0, 1, getImage(localFile));
          }
          return (
            <Article key={node.slug.current} id={formatAnchor(node.startDate)}>
              <Header>
                <h3>
                  <a href={node.eventLink} target="_blank">{displayDate}</a>
                </h3>
              </Header>
              <Carousel images={images} />
            </Article>
          )
        })}
      </div>
    </React.Fragment>
  )
}

export default MerchImagesPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`